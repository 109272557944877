import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  // no auth guard
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  // no auth guard
  {
    path: 'password-reset',
    loadChildren:
      './password-reset/password-reset.module#PasswordResetPageModule',
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'sales-history',
    loadChildren: './sales-history/sales-history.module#SalesHistoryPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-orders',
    loadChildren:
      './purchase-orders/purchase-orders.module#PurchaseOrdersPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    loadChildren: './inventory/inventory.module#InventoryPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'shop-management',
    loadChildren: './shop-management/shop-management.module#ShopManagementPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile-ux', loadChildren: './core/ux/mobile-ux/mobile-ux.module#MobileUxPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'tablet-ux', loadChildren: './core/ux/tablet-ux/tablet-ux.module#TabletUxPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'Sell Through', loadChildren: './sell-through/sell-through.module#SellThroughPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'shop-management', loadChildren: './shop-management/shop-management.module#ShopManagementPageModule',
    canActivate: [AuthGuard]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
