import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BearerAuthInterceptor } from './core/interceptors/bearer-auth';
import { IonicStorageModule } from '@ionic/storage';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { CustomPinnedRowRenderer } from './core/components/general-components/custom-pinned-row-renderer/custom-pinned-row-renderer.component';

@NgModule({
  declarations: [AppComponent, CustomPinnedRowRenderer],
  entryComponents: [],
  imports: [
    BrowserModule,
	IonicModule.forRoot(),
	IonicStorageModule.forRoot(),
    AppRoutingModule,
	HttpClientModule,
	AgGridModule.withComponents([])	
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { 
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy 
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: BearerAuthInterceptor, multi: true
	}
  

],
  bootstrap: [AppComponent]
})
export class AppModule {}
