import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class AppConfigService {
  public endpoint: string = environment.API_ENDPOINT;
  public cobeWebService = environment.COBE_WEB_SERVICE;
  public emailRegex = '[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+[.][a-zA-Z]{2,5}';

  constructor() {}
}
