import { Component, OnInit } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Sales',
      url: '/sales-history',
      icon: 'trending-up'
    },
    {
      title: 'Inventory',
      url: '/inventory',
      icon: 'paper'
    },
    {
      title: 'Shop Manager',
      url: '/shop-management',
      icon: 'appstore'
    }
    // ,
    // {
    //   title: 'Purchase Orders',
    //   url: '/purchase-orders',
    //   icon: 'cash'
    // }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
  }

  initializeApp() {
    this
      .platform
      .ready()
      .then(() => {
        this
          .statusBar
          .styleDefault();
        this
          .splashScreen
          .hide();
      });
  }

  ngOnInit() { 
    this.initializeApp();
  }
}
