// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class BearerAuthInterceptor implements HttpInterceptor {

  /**
   * we don't add auth to requests to the auth endpoint EX login, forgot password
   */
  private whitelist = ['/api/auth'];

  constructor(private auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.urlInWhitelist(request.url)) {
      return next.handle(request);
    }
    const token = this.auth.getToken()
    if (!token) {
      return next.handle(request);
    }
    return next.handle(request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    }));
  }
  urlInWhitelist(url: string): boolean {
    return this.whitelist.reduce((inWhitelist: boolean, whitelistedString: string) => {
      return inWhitelist || url.includes(whitelistedString)
    }, false);
  }
}