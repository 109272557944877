import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AppConfigService } from './../config/app.config';
import { User } from '../models/user.model';
import { IAuthCredentials, IAuthResponse } from './../models/auth-data.model';
import { map } from 'rxjs/operators';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

export interface ILocalAuthData { token: string, expirationDate: Date, userData: any };

@Injectable({ providedIn: 'root' })
export class AuthService {
  private token: string;
  private tokenTimer: any;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private http: HttpClient,
    private navCtrl: NavController,
    private appConfig: AppConfigService) { }

  getTokenIsValid(): boolean {
    const authData: ILocalAuthData = this.getAuthData();
    if (!authData || !authData.token) {
      return false;
    }
    const now = new Date();
    const expiresIn = authData
      .expirationDate
      .getTime() - now.getTime();

    if (expiresIn > 0) {
      return true;
    } else {
      return false;
    }
  }

  getToken(): string {
    const authData: ILocalAuthData = this.getAuthData();
    return authData.token;
  }

  getLoggedInUser(): User {
    const authData: ILocalAuthData = this.getAuthData();
    return authData && authData.userData
  }

  login(email: string, password: string): Observable<any> {
    const authData: IAuthCredentials = {
      email: email,
      password: password
    };

    return this.http.post<IAuthResponse>(this.appConfig.endpoint + '/api/auth/login', authData, { observe: 'response' })
      .pipe(map((response: HttpResponse<IAuthResponse>) => {

          const authResponse: IAuthResponse = response.body;

          if (authResponse.userRole !== 'sales-rep') {
            alert('Shop and Admin users please use mainstreet.com');
          }

          this.token = authResponse && authResponse.token;
          const expiresInDuration = authResponse.expiresIn;
          this.setAuthTimer(expiresInDuration);  // set timer for expiration

          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);

          // sets the auth data in local storage, so refreshing the page keeps your token
          this.saveAuthData(this.token, expirationDate, authResponse);
      }));
  }

  logout(): void {
    this.token = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.navCtrl.navigateRoot('/login');
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, userData: User): void {
    this.storage.set('token', token);
    this.storage.set('userData', userData);
    this.storage.set('expiration', expirationDate.toISOString());
  }

  private clearAuthData(): void {
    this.storage.remove('token'),
      this.storage.remove('expiration'),
      this.storage.remove('userData')
  }

  private getAuthData(): ILocalAuthData {
    const token: string = this.storage.get('token');
    const expirationDate: string = this.storage.get('expiration');
    const userData: User = this.storage.get('userData');

    if (!token || !expirationDate) {
      return;
    }
    return { token, expirationDate: new Date(expirationDate), userData };
  }
}
